var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"align-self":"center","sm":"12","md":"6"}},[_c('span',{staticStyle:{"font-weight":"100","font-size":"35px","color":"white"}},[_vm._v(" Meniu Mancare ")])])],1),_c('v-spacer'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"12","md":"9","lg":"6"}},[_c('v-expansion-panels',{staticClass:"mb-6",staticStyle:{"opacity":"90%"}},_vm._l((_vm.categoriiMancare),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"100","font-size":"17px"},attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"transparent","hover-reveal":"","image":""},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":"https://images.pexels.com/photos/4141026/pexels-photo-4141026.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}})]},proxy:true},{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('span',{staticStyle:{"padding-top":"20px"}},[_vm._v("Details")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light grey--text"},[_vm._v(" 20 lei ")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"1","color":"blue","dark":"","height":"35px","max-width":"20%"},on:{"click":function($event){_vm.shoppingCart = 'nice omlette'; _vm.logChange()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cart-arrow-down ")])],1)]},proxy:true}],null,true)},[_c('v-card-title',{staticClass:"justify-center font-weight-light text-center",staticStyle:{"word-break":"keep-all"}},[_vm._v(" Nice Omlette ")]),_c('v-card-text',{staticClass:"body-1 text-center mb-3 font-weight-light grey--text"},[_vm._v(" Omleta cu branza si sunca pe gustul tuturor. ")])],1)],1),_c('v-col',{attrs:{"sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"transparent","hover-reveal":"","image":""},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":"https://images.pexels.com/photos/103124/pexels-photo-103124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}})]},proxy:true},{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('span',{staticStyle:{"padding-top":"20px"}},[_vm._v("Details")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light grey--text"},[_vm._v(" 30 lei ")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"1","color":"blue","dark":"","height":"35px","max-width":"20%"},on:{"click":function($event){_vm.shoppingCart = 'breakfast menu'; _vm.logChange()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cart-arrow-down ")])],1)]},proxy:true}],null,true)},[_c('div',{staticStyle:{"word-break":"keep-all"}},[_c('v-card-title',{staticClass:"justify-center font-weight-light text-center",staticStyle:{"word-break":"keep-all"}},[_vm._v(" Breakfast Menu ")]),_c('v-card-text',{staticClass:"body-1 text-center mb-3 font-weight-light grey--text"},[_vm._v(" A delight with everything included. ")])],1)])],1),_c('v-col',{attrs:{"sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"transparent","hover-reveal":"","image":""},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":"https://images.pexels.com/photos/103124/pexels-photo-103124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}})]},proxy:true},{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('span',{staticStyle:{"padding-top":"20px"}},[_vm._v("Details")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light grey--text"},[_vm._v(" 30 lei ")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"1","color":"blue","dark":"","height":"35px","max-width":"20%"},on:{"click":function($event){_vm.shoppingCart = 'breakfast menu'; _vm.logChange()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cart-arrow-down ")])],1)]},proxy:true}],null,true)},[_c('v-card-title',{staticClass:"justify-center font-weight-light text-center",staticStyle:{"word-break":"keep-all"}},[_vm._v(" Breakfast Menu ")]),_c('v-card-text',{staticClass:"body-1 text-center mb-3 font-weight-light grey--text"},[_vm._v(" A delight with everything included. ")])],1)],1),_c('v-col',{attrs:{"sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"transparent","hover-reveal":"","image":""},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":"https://images.pexels.com/photos/103124/pexels-photo-103124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}})]},proxy:true},{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('span',{staticStyle:{"padding-top":"20px"}},[_vm._v("Details")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light grey--text"},[_vm._v(" 30 lei ")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"1","color":"blue","dark":"","height":"35px","max-width":"20%"},on:{"click":function($event){_vm.shoppingCart = 'breakfast menu'; _vm.logChange()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cart-arrow-down ")])],1)]},proxy:true}],null,true)},[_c('v-card-title',{staticClass:"justify-center font-weight-light text-center",staticStyle:{"word-break":"keep-all"}},[_vm._v(" Breakfast Menu ")]),_c('v-card-text',{staticClass:"body-1 text-center mb-3 font-weight-light grey--text"},[_vm._v(" A delight with everything included. ")])],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('span',{staticStyle:{"font-weight":"100","font-size":"35px","color":"white"}},[_vm._v(" Meniu Bauturi ")])])],1),_c('v-spacer'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-expansion-panels',{staticClass:"mb-6",staticStyle:{"opacity":"90%"}},_vm._l((_vm.categoriiBauturi),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"100","font-size":"17px"},attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]),_c('v-expansion-panel-content',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.")])],1)}),1)],1)],1),_c('div',{staticStyle:{"height":"100px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }