<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        align-self="center"
        sm="12"
        md="6"
      >
        <span style="font-weight: 100; font-size: 35px; color: white">
          Meniu Mancare
        </span>
      </v-col>
    </v-row>
    <v-spacer />
    <v-row justify="center">
      <v-col
        sm="12"
        md="9"
        lg="6"
      >
        <v-expansion-panels
          class="mb-6"
          style="opacity: 90%"
        >
          <v-expansion-panel
            v-for="(item,i) in categoriiMancare"
            :key="i"
          >
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
              style="font-weight: 100; font-size: 17px"
            >
              {{ $t(item) }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row justify="center">
                <v-col
                  sm="6"
                  md="4"
                >
                  <base-material-card
                    color="transparent"
                    hover-reveal
                    image
                  >
                    <template v-slot:image>
                      <v-img
                        src="https://images.pexels.com/photos/4141026/pexels-photo-4141026.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                      />
                    </template>

                    <template v-slot:reveal-actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            class="mx-1"
                            v-bind="attrs"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-help</v-icon>
                          </v-btn>
                        </template>

                        <span>Details</span>
                      </v-tooltip>
                      <span style="padding-top: 20px">Details</span>
                    </template>

                    <v-card-title
                      class="justify-center font-weight-light text-center"
                      style="word-break: keep-all"
                    >
                      Nice Omlette
                    </v-card-title>

                    <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
                      Omleta cu branza si sunca pe gustul tuturor.
                    </v-card-text>

                    <template v-slot:actions>
                      <div class="display-2 font-weight-light grey--text">
                        20 lei
                      </div>

                      <v-spacer />

                      <v-btn
                        elevation="1"
                        color="blue"
                        dark
                        height="35px"
                        max-width="20%"
                        @click="shoppingCart = 'nice omlette'; logChange()"
                      >
                        <v-icon
                          left
                        >
                          mdi-cart-arrow-down
                        </v-icon>
                      </v-btn>
                    </template>
                  </base-material-card>
                </v-col>
                <v-col
                  sm="6"
                  md="4"
                >
                  <base-material-card
                    color="transparent"
                    hover-reveal
                    image
                  >
                    <template v-slot:image>
                      <v-img
                        src="https://images.pexels.com/photos/103124/pexels-photo-103124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      />
                    </template>

                    <template v-slot:reveal-actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            class="mx-1"
                            v-bind="attrs"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-help</v-icon>
                          </v-btn>
                        </template>

                        <span>Details</span>
                      </v-tooltip>
                      <span style="padding-top: 20px">Details</span>
                    </template>
                    <div style="word-break: keep-all">
                      <v-card-title
                        class="justify-center font-weight-light text-center"
                        style="word-break: keep-all"
                      >
                        Breakfast Menu
                      </v-card-title>

                      <v-card-text
                        class="body-1 text-center mb-3 font-weight-light grey--text"
                      >
                        A delight with everything included.
                      </v-card-text>
                    </div>
                    <template v-slot:actions>
                      <div class="display-2 font-weight-light grey--text">
                        30 lei
                      </div>

                      <v-spacer />

                      <v-btn
                        elevation="1"
                        color="blue"
                        dark
                        height="35px"
                        max-width="20%"
                        @click="shoppingCart = 'breakfast menu'; logChange()"
                      >
                        <v-icon
                          left
                        >
                          mdi-cart-arrow-down
                        </v-icon>
                      </v-btn>
                    </template>
                  </base-material-card>
                </v-col>
                <v-col
                  sm="6"
                  md="4"
                >
                  <base-material-card
                    color="transparent"
                    hover-reveal
                    image
                  >
                    <template v-slot:image>
                      <v-img
                        src="https://images.pexels.com/photos/103124/pexels-photo-103124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      />
                    </template>

                    <template v-slot:reveal-actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            class="mx-1"
                            v-bind="attrs"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-help</v-icon>
                          </v-btn>
                        </template>

                        <span>Details</span>
                      </v-tooltip>
                      <span style="padding-top: 20px">Details</span>
                    </template>

                    <v-card-title
                      class="justify-center font-weight-light text-center"
                      style="word-break: keep-all"
                    >
                      Breakfast Menu
                    </v-card-title>

                    <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
                      A delight with everything included.
                    </v-card-text>

                    <template v-slot:actions>
                      <div class="display-2 font-weight-light grey--text">
                        30 lei
                      </div>

                      <v-spacer />

                      <v-btn
                        elevation="1"
                        color="blue"
                        dark
                        height="35px"
                        max-width="20%"
                        @click="shoppingCart = 'breakfast menu'; logChange()"
                      >
                        <v-icon
                          left
                        >
                          mdi-cart-arrow-down
                        </v-icon>
                      </v-btn>
                    </template>
                  </base-material-card>
                </v-col>
                <v-col
                  sm="6"
                  md="4"
                >
                  <base-material-card
                    color="transparent"
                    hover-reveal
                    image
                  >
                    <template v-slot:image>
                      <v-img
                        src="https://images.pexels.com/photos/103124/pexels-photo-103124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      />
                    </template>

                    <template v-slot:reveal-actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            class="mx-1"
                            v-bind="attrs"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-help</v-icon>
                          </v-btn>
                        </template>

                        <span>Details</span>
                      </v-tooltip>
                      <span style="padding-top: 20px">Details</span>
                    </template>

                    <v-card-title
                      class="justify-center font-weight-light text-center"
                      style="word-break: keep-all"
                    >
                      Breakfast Menu
                    </v-card-title>

                    <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
                      A delight with everything included.
                    </v-card-text>

                    <template v-slot:actions>
                      <div class="display-2 font-weight-light grey--text">
                        30 lei
                      </div>

                      <v-spacer />

                      <v-btn
                        elevation="1"
                        color="blue"
                        dark
                        height="35px"
                        max-width="20%"
                        @click="shoppingCart = 'breakfast menu'; logChange()"
                      >
                        <v-icon
                          left
                        >
                          mdi-cart-arrow-down
                        </v-icon>
                      </v-btn>
                    </template>
                  </base-material-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        sm="12"
        md="6"
      >
        <span style="font-weight: 100; font-size: 35px; color: white">
          Meniu Bauturi
        </span>
      </v-col>
    </v-row>
    <v-spacer />
    <v-row justify="center">
      <v-col
        sm="12"
        md="6"
      >
        <v-expansion-panels
          class="mb-6"
          style="opacity: 90%"
        >
          <v-expansion-panel
            v-for="(item,i) in categoriiBauturi"
            :key="i"
          >
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
              style="font-weight: 100; font-size: 17px"
            >
              {{ $t(item) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div style="height: 100px" />
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        categoriiMancare: ['Mic dejun', 'Bruschete', 'Gustari Reci', 'Fructe de mare', 'Supe creme & ciorbe', 'Salate', 'Focaccia', 'Pasta', 'Pizza', 'Preparate din porc', 'Preparate din pui', 'Preparate din vita', 'Preparate din berbecut', 'Peste', 'Risotto', 'Garnituri', 'Deserturi', 'Meniu Romanesc', 'Meniul zilei'],
        categoriiBauturi: ['Racoritoare', 'Bere', 'Vinuri', 'Cocktails', 'Tarii', 'Bauturi calde/reci', 'Tigari'],
      }
    },

    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },

      shoppingCart: {
        get () { return this.$store.getters.get_cart },
        set (val) {
          this.$store.commit('SET_CART', val)
        },
      },
    },

    mounted () {
      // console.log(this.shoppingCart)
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },

      logChange () {
        // console.log(this.shoppingCart)
      },
    },
  }
</script>
